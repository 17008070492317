import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LogoColor from "../assets/images/LogoColor.svg";
import Waves from "../assets/images/waves.svg";
import { Alert, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { useEffect } from "react";
import colombia from "../JSONs/colombia.json";
import spain from "../JSONs/spain.json";
import AuthService from "../services/AuthService";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import useAuth from "../context/auth/useAuth";
// import dayjs from 'dayjs';
import { convertDate2, RemoveTilde } from '../utils/Util'

const initialData = {
    username: "",
    fullname: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    confirmPassword: "",
    birthdate: Date.now(),
    typeUser: "user",
    state: "",
    city: "",
    country: "",
    accept: false
}

export default function Register() {
    //Reg Exp
    let isValidNumCont = /^[0-9]{8,10}$/g
    let validEmail = /^[A-Za-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[A-Za-z0-9*+/={|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/g
    let validUsername = /^(?!.*\s)[a-zA-Z0-9]{3,30}(?!.*\s)$/g

    const { register } = useAuth();

    //navigation
    const navigate = useNavigate();

    //Form data
    const [formData, setFormData] = React.useState(initialData)
    const [errors, setErrors] = React.useState({});

    //cities list state
    const [municipalityList, setMunicipalityList] = useState([]);

    //handler iunputs to all TextFields
    const handlerInputChange = (e) => {
        setErrors({});
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData(formInfo => {
            return {
                ...formInfo,
                [name]: value
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let newErrors = {};

        if (formData.username === "") newErrors.username = "El usuario es requerido";
        if (formData.fullname === "") newErrors.fullname = "El nombre completo es requerido";
        if (formData.email === "") newErrors.email = "El correo es requerido";
        if (formData.phone === "") newErrors.phone = "El telefono es requerida";
        if (formData.gender === "") newErrors.gender = "El genero es requerido";
        if (formData.country === "") newErrors.country = "El país es requerido";
        if (formData.state === "") newErrors.department = "El departamento es requerida";
        if (formData.city === "") newErrors.municipality = "El ayuntamiento es requerido";
        if (formData.birthdate === Date.now()) newErrors.birthdate = "La fecha de nacimiento es requerida y debe ser diferente a la fecha de hoy";
        if (formData.password === "") newErrors.password = "La contraseña es requerida";
        if (formData.confirmPassword === "") newErrors.confirmPassword = "Confirmar contraseña es requerido";

        if (formData.password !== "" && formData.confirmPassword !== "" && formData.password !== formData.confirmPassword) {
            newErrors.password = "Las contraseñas no coinciden";
            newErrors.confirmPassword = "Las contraseñas no coinciden";
        }

        if (formData.accept === false) newErrors.accept = "Debes aceptar nuestros términos y condiciones";

        if (formData.username !== "") {
            let isValidUsername = formData.username.match(validUsername)
            if (!isValidUsername) {
                newErrors.username = 'El usuario no es válido, debe contener sólo letras y números, mayor a 3 caracteres y no debe contener espacios en blanco.';
            }
        }

        if (formData.email !== "") {
            let isValidEmail = formData.email.match(validEmail)
            if (!isValidEmail) {
                newErrors.email = 'El correo electrónico de contacto que ingresate no es válido';
            }
        }

        if (formData.phone !== "") {
            let isValidNumber = formData.phone.match(isValidNumCont)
            if (!isValidNumber) {
                newErrors.phone = 'El número de celular que ingresate no es válido debe contener entre 8 y 10 dígitos';
            }
        }

        if (Object.keys(newErrors).length === 0) {
            postSign(formData);
        } else {
            setErrors(newErrors);
        }
    };

    const postSign = async (formData) => {

        let newFormData = {
            ...formData,
            username: RemoveTilde(formData.username).toLowerCase(),
            fullname: RemoveTilde(formData.fullname),
        }

        try {
            const response = await AuthService.signUpV2(newFormData);
            register(response);
            navigate("/register/step/2")
        } catch (error) {
            console.log(error)
            setErrors({ formError: error.message })
        }
    };

    useEffect(() => {

        if (formData.country === "colombia") {
            colombia.forEach((loc) => {
                if (loc.nombre === formData.state) {
                    setMunicipalityList(loc.municipios);
                }
            });
        }

        if (formData.country === "spain") {
            spain.forEach((loc) => {
                if (loc.nombre === formData.state) {
                    setMunicipalityList(loc.provincias);
                }
            });
        }

    }, [formData.country, formData.state]);


    return (
        <>
            <Container component="main" maxWidth="xs" sx={{ zIndex: 100, mb: 20, position: "relative" }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar src={LogoColor} sx={{ width: 100, height: 100, m: 4 }} />
                    <Typography component="h1" variant="h5" textAlign={"center"}>
                        El momento de vivir en comunidad ha llegado.
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="off"
                                    name="username"
                                    required
                                    fullWidth
                                    id="username"
                                    value={formData.username}
                                    onChange={(e) => handlerInputChange(e)}
                                    label="Nombre de usuario"
                                    error={!!errors.username}
                                    helperText={errors.username ? errors.username : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="fullname"
                                    label="Nombre completo"
                                    name="fullname"
                                    value={formData.fullname}
                                    onChange={(e) => handlerInputChange(e)}
                                    autoComplete="off"
                                    error={!!errors.fullname}
                                    helperText={errors.fullname ? errors.fullname : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handlerInputChange(e)}
                                    autoComplete="off"
                                    error={!!errors.email}
                                    helperText={errors.email ? errors.email : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Telefono"
                                    name="phone"
                                    type="number"
                                    autoComplete="off"
                                    value={formData.phone}
                                    onChange={(e) => handlerInputChange(e)}
                                    error={!!errors.phone}
                                    helperText={errors.phone ? errors.phone : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Genero</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Genero"
                                        name={"gender"}
                                        value={formData.gender}
                                        onChange={(e) => handlerInputChange(e)}
                                        error={!!errors.gender}
                                    >
                                        <MenuItem value={"male"}>Masculino</MenuItem>
                                        <MenuItem value={"female"}>Femenino</MenuItem>
                                        <MenuItem value={"other"}>Otro</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        {errors.gender ? errors.gender : ""}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Ubicacion</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Ubicacion"
                                        name={"country"}
                                        value={formData.country}
                                        onChange={(e) => handlerInputChange(e)}
                                        error={!!errors.country}
                                    >
                                        <MenuItem value={"spain"}>España</MenuItem>
                                        <MenuItem value={"colombia"}>Colombia</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        {errors.country ? errors.country : ""}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            {formData.country !== "" && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                {formData.country === "spain" ? "Comunidad" : "Departamento"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Departamento"
                                                name="state"
                                                value={formData.state}
                                                onChange={(e) => handlerInputChange(e)}
                                                error={!!errors.state}
                                            >
                                                {formData.country === "colombia" && colombia.map((loc, index) => (
                                                    <MenuItem key={index} value={loc.nombre}>
                                                        {loc.nombre}
                                                    </MenuItem>
                                                ))}
                                                {formData.country === "spain" && spain.map((loc, index) => (
                                                    <MenuItem key={index} value={loc.nombre}>
                                                        {loc.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>
                                                {errors.state ? errors.state : ""}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                {formData.country === "spain" ? "Provincia" : "Municipio"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Municipio"
                                                name="city"
                                                value={formData.city}
                                                onChange={(e) => handlerInputChange(e)}
                                                error={!!errors.city}
                                            >
                                                {municipalityList.map((mun, index) => (
                                                    <MenuItem key={index} value={mun}>
                                                        {mun}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>
                                                {errors.municipality ? errors.municipality : ""}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            <Grid
                                item
                                xs={12}
                                justifyContent={"center"}
                                alignItems={"center"}
                                display={"flex"}
                            >
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="date"
                                    label="Fecha de nacimiento"
                                    name={"birthdate"}
                                    value={convertDate2(formData.birthdate)}
                                    onChange={(e) => handlerInputChange(e)}
                                    error={!!errors.birthdate}
                                    helperText={errors.birthdate ? errors.birthdate : ""}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    value={formData.password}
                                    onChange={(e) => handlerInputChange(e)}
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    error={!!errors.password}
                                    helperText={errors.password ? errors.password : ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={(e) => handlerInputChange(e)}
                                    label="Confirmar Contraseña"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="off"
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword ? errors.confirmPassword : ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name="accept" checked={formData.accept} onChange={handlerInputChange} inputProps={{ 'aria-label': 'controlled' }} />} label="Acepta los términos y condiciones" />
                                    <Typography sx={{ color: 'red', fontSize: 12, fontWeight: 'bold' }}>{errors.accept ? errors.accept : ""}</Typography>
                                </FormGroup>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    errors.formError && <Alert severity="error">{errors.formError}</Alert>
                                }
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Registrarme
                        </Button>
                    </Box>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
            <Box
                component={"img"}
                src={Waves}
                sx={{ width: "100%", bottom: 0, position: "fixed" }}
            />
        </>
    );
}
