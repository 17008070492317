import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Avatar } from "@mui/material";
import Logo from "../assets/images/Logo.svg";
import { useNavigate } from "react-router-dom";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MenuIcon from "@mui/icons-material/Menu";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CloseIcon from '@mui/icons-material/Close';

const actions = [
  { icon: <HomeOutlinedIcon />, name: "Inicio", route: "/" },
  { icon: <WhatshotIcon />, name: "Tendencias", route: "/trending" },
  { icon: <ChatOutlinedIcon />, name: "Chats", route: "/chats" },
  { icon: <PersonOutlinedIcon />, name: "Perfil", route: "/profile" },
  { icon: <SettingsOutlinedIcon />, name: "Ajustes", route: "/settings" },
];

export default function Header() {

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      position="fixed"
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        bottom: { xs: "auto", md: "auto" },
        top: { xs: 0, md: 0 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        height: 60,
        flexDirection: "row",
        zIndex: 200
      }}
    >
      <Box
      onClick={() => {navigate("/")}}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          ml: {xs: 1, md: 3},
          mt: {xs: 1, md: 2},
          cursor: "pointer"
        }}
      >
        <Avatar
          src={Logo}
          sx={{ display: { xs: "flex", md: "flex", width: 40, height: 40 } }}
        />
        {!window.location.pathname.includes("trending") && (
             <Typography
             sx={{
               fontSize: 18,
               ml: 1,
               color: "#8B4DBA",
             }}
           >
             Hoshii
           </Typography>
        )}
      </Box>
      <SpeedDial
              ariaLabel="Menu"
              icon={!open ? <MenuIcon/> : <CloseIcon/>}
              direction="down"
              onClose={() => setOpen(false)}
              onOpen={() => {
                setOpen(true);
              }}
              open={open}
              sx={{
                position: "fixed",
                top: 0,
                right: {xs: 2, md: 5},
                mt: {xs: 1, md: 2},
                '& .MuiFab-primary': { width: 40, height: 40
                }
              }}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => navigate(action.route)}
                />
              ))}
            </SpeedDial>
    </Box>
  );
}
