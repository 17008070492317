import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";

//form country locations list
import colombia from "../JSONs/colombia.json";
import spain from "../JSONs/spain.json";

//auth
import useAuth from "../context/auth/useAuth";

//services
import AccountService from "../services/AccountService";
import SettingsService from "../services/SettingsService";

//material
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ReportIcon from "@mui/icons-material/Report";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  TextareaAutosize as BaseTextareaAutosize,
  Typography,
} from "@mui/material";
import { MenuItem } from "@mui/material";

//custom components
import Header from "../components/Header";

//waves
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";

//social media icons
import facebook from "../assets/socialMedia/facebook.png";
import instagram from "../assets/socialMedia/instagram.png";
import snapchat from "../assets/socialMedia/snapchat.png";
import linkedin from "../assets/socialMedia/linkedin.png";
import tiktok from "../assets/socialMedia/tiktok.png";
import twitter from "../assets/socialMedia/twitter.png";
import onlyfans from "../assets/socialMedia/onlyfans.png";

//notifications
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import ChangeProfileImages from "../components/settings/ChangeProfileImages";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const socialMediaArray = [
  {
    name: "facebook",
    image: facebook,
  },
  {
    name: "instagram",
    image: instagram,
  },
  {
    name: "twitter",
    image: twitter,
  },
  {
    name: "tiktok",
    image: tiktok,
  },
  {
    name: "linkedin",
    image: linkedin,
  },
  {
    name: "snapchat",
    image: snapchat,
  },
  {
    name: "onlyfans",
    image: onlyfans,
  },
];

const socialMediaInitialBase = [
  {
    name: "facebook",
    url: "",
  },
  {
    name: "instagram",
    url: "",
  },
  {
    name: "twitter",
    url: "",
  },
  {
    name: "tiktok",
    url: "",
  },
  {
    name: "linkedin",
    url: "",
  },
  {
    name: "snapchat",
    url: "",
  },
  {
    name: "onlyfans",
    url: "",
  },
];

const Settings = () => {
  //auth states
  const { user, logout } = useAuth();
  const userData = user;
  const token = userData?.accessToken ? userData.accessToken : null;

  // const [newAccount, setNewAccount] = useState("");
  const [openChangeProfileImage, setOpenChangeProfileImage] =
    React.useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  const [errors, setErrors] = React.useState({});

  const [load, setLoad] = useState(true);

  const [buttonsLoad, setButtonsLoad] = useState({
    fullname: false,
    description: false,
    userInfoForm: false,
    newPassword: false,
    delete: false,
    image: false,
  });

  //change profile info states
  const [settingInfo, setSettingsInfo] = React.useState({
    fullname: "",
    description: "",
    email: "",
    phone: "",
    gender: "",
    country: "",
    department: "",
    municipality: "",
  });

  //new password states
  const [oldPassword, setOldPassword] = useState("");
  const [modalPassword, setModalPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  //modals states
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSocialModal, setOpenSocialModal] = React.useState(false);
  const handleOpenSocialModal = () => setOpenSocialModal(true);
  const handleCloseSocialModal = () => setOpenSocialModal(false);
  const [currentSocialMedia, setCurrentSocialMedia] = React.useState({});

  const uploadForm = React.useRef(null);

  const [country, setCountry] = useState("");

  const [countryState, setCountryState] = useState("");

  const [municipalityList, setMunicipalityList] = useState([]);

  const [activeAccount, setActiveAccount] = useState(false);

  const [socialMediaLink, setSocialMediaLink] = useState("");

  const [accountCurrentSocialMedia, setAccountCurrentSocialMedia] = useState([]);

  const getAccountData = React.useCallback(async () => {
    try {
      const accountData = await AccountService.getAccount(token);
      if (accountData.code === "EXISTACCOUNT") {
        // setNewAccount(res.account);

        // console.log(accountData)

        setAccountCurrentSocialMedia(accountData.account.socialMedia)

        setActiveAccount(accountData.account.active);
        setSettingsInfo({
          fullname: accountData.account.fullname,
          description: accountData.account.description,
          email: user.email,
          phone: user.phone,
          gender: user.gender,
          country: user.country,
          department: user.state,
          municipality: user.city,
        });
        setCountry(user.country);
        setSelectedImage(accountData.account.pic_profile);
        setLoad(false);
      } else {
        console.log(accountData.msg);
        setLoad(false);
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      setErrors(error.message);
    }
  }, [
    token,
    user.city,
    user.email,
    user.gender,
    user.phone,
    user.state,
    user.country,
  ]);

  React.useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  const handleChangeData = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSettingsInfo((settingInfo) => {
      return {
        ...settingInfo,
        [name]: value,
      };
    });
  };

  const UpdateAccountInfo = async (e) => {
    e.preventDefault();

    setButtonsLoad({ ...buttonsLoad, userInfoForm: true });

    const accountInfo = {
      email: settingInfo.email,
      phone: settingInfo.phone,
      gender: settingInfo.gender,
      country: settingInfo.country,
      state: settingInfo.department,
      city: settingInfo.municipality,
      SameEmail: user.email === settingInfo.email ? true : false,
      SamePhone: user.phone === settingInfo.phone ? true : false,
    };

    const data = await SettingsService.EditAccountData(token, accountInfo);

    // console.log(data);

    if (data.code === "200") {
      // console.log(data);

      const newUser = data.user;

      newUser.accessToken = token;

      localStorage.setItem("hoshii_user", JSON.stringify(newUser));

      setButtonsLoad({ ...buttonsLoad, userInfoForm: false });

      toast.success("Se han actualizado los datos correctamente!", {
        autoClose: 2000,
      });
    } else {
      setButtonsLoad({ ...buttonsLoad, userInfoForm: false });

      toast.error("Oops parece que ha habido un error.", { autoClose: 2000 });
    }
  };

  const handleInputImagePic = (e) => {
    const [file] = e.target.files;
    const imgSize7Mb = 7 * 1024 * 1024;
    const isValidSize = file.size < imgSize7Mb;
    const isNameOfOneImageRegEx = /.(jpe?g|gif|png|webp)$/i;
    const isValidType = isNameOfOneImageRegEx.test(file.name);

    if (!isValidSize) {
      e.target.value = "";
      return toast.error("La imagen es demasido pesada, máximo 5mb", {
        autoClose: 1500,
      });
    }
    if (!isValidType) {
      e.target.value = "";
      return toast.error(
        "La imagen solo puede tener formato .jpg, .png, .jpeg, .gif, .webp",
        { autoClose: 1500 }
      );
    }

    const reader = new FileReader();
    reader.onloadstart = () => {};
    reader.onloadend = () => {
      //console.log(reader.result)
      setSelectedImage(reader.result);
      let btnSubmitUpdate = document.getElementById("btnSubmitUpdateProfile");
      btnSubmitUpdate.click();
    };
    reader.readAsDataURL(file);

    // handleFormChange()
  };

  const handleSubmitImage = async (e) => {
    e.preventDefault();

    setButtonsLoad({ ...buttonsLoad, image: true });

    if (!selectedImage) {
      setButtonsLoad({ ...buttonsLoad, image: false });
      toast.error("Debes seleccionar una imagen", { autoClose: 2000 });
    } else {
      const form = uploadForm.current;
      const fileInput = Array.from(form.elements).find(
        ({ name }) => name === "file"
      );

      const formData = new FormData();
      for (const file of fileInput.files) {
        formData.append("file", file);
      }

      const updateImageProfile = await AccountService.updateImageProfile(
        token,
        formData
      );
      // console.log(updateImageProfile);
      if (updateImageProfile.code === "UPDATEDPICPROFILE") {
        // setNewAccount({ ...account, fullname: res.fullnameUpdated.fullname })
        setButtonsLoad({ ...buttonsLoad, image: false });
        toast.success(
          "La foto de perfil se ha actualizado correctamente",
          1500
        );
        if (
          selectedImage !==
            "https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png" &&
          settingInfo.description !== null &&
          settingInfo.description !== "" &&
          selectedImage !== ""
        ) {
          setActiveAccount(true);
        }
      } else {
        setButtonsLoad({ ...buttonsLoad, image: false });
        toast.error("Hubo un error al actualizar la imagen", 1500);
      }
    }
  };

  const handleSubmit = async () => {
    setButtonsLoad({ ...buttonsLoad, fullname: true });

    if (settingInfo.fullname !== "") {
      const updatedFullname = AccountService.updateFullname(
        token,
        settingInfo.fullname
      );
      updatedFullname
        .then((res) => {
          if (res.code === "UPDATEFULLNAME") {
            setSettingsInfo({
              ...settingInfo,
              fullname: res.fullnameUpdated.fullname,
            });
            setButtonsLoad({ ...buttonsLoad, fullname: false });
            toast.success("El nombre se ha actualizado correctamente", 1500);
          } else {
            setButtonsLoad({ ...buttonsLoad, fullname: false });
            toast.error(res.msg, 1500);
          }
        })
        .catch((e) => {
          switch (e.name) {
            case "TypeError":
              throw new Error("Failed to load server!, please try later");
            default:
              throw new Error("Failed to connection server!, please try later");
          }
        });
    } else {
      setButtonsLoad({ ...buttonsLoad, fullname: false });
      toast.error("Debes indicar el nombre a actualizar", 1500);
    }
  };

  const handleSubmitDesc = async (e) => {
    setButtonsLoad({ ...buttonsLoad, description: true });

    if (settingInfo.description !== "") {
      const data = await AccountService.updateDescription(
        token,
        settingInfo.description
      );

      if (data.code === "UPDATEDESCRIPTION") {
        setSettingsInfo({
          ...settingInfo,
          description: settingInfo.description,
        });
        setButtonsLoad({ ...buttonsLoad, description: false });
        toast.success("La descripcion se ha actualizado correctamente", 1500);
        if (
          selectedImage !==
            "https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png" &&
          settingInfo.description !== null &&
          settingInfo.description !== "" &&
          selectedImage !== ""
        ) {
          setActiveAccount(true);
        }
      } else {
        setButtonsLoad({ ...buttonsLoad, description: false });
        toast.error(data.msg, 1500);
      }
    } else {
      setButtonsLoad({ ...buttonsLoad, description: false });
      toast.error("Debes indicar la descripcion a actualizar", 1500);
    }
  };

  const handleChangePasswords = (e) => {
    switch (e.target.name) {
      case "oldPassword":
        setOldPassword(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(e.target.value);
        break;
      default:
        console.log(e.target.value);
    }
  };

  const UpdateAccountPassword = async (e) => {
    e.preventDefault();

    setButtonsLoad({ ...buttonsLoad, newPassword: true });

    const passInfo = {
      password: oldPassword,
      newPassword: newPassword,
    };

    if (newPassword === confirmNewPassword) {
      const data = await SettingsService.EditAccountPassword(token, passInfo);

      if (data.code === "200") {
        setButtonsLoad({ ...buttonsLoad, newPassword: false });
        toast.success("Tu contraseña se ha actualizado correctamente!", {
          autoClose: 2000,
        });
      } else {
        console.log(data);
        setButtonsLoad({ ...buttonsLoad, newPassword: false });
        toast.error(
          "Oops parece que ha habido un error, por favor intentalo mas tarde",
          { autoClose: 2000 }
        );
      }
    } else {
      setButtonsLoad({ ...buttonsLoad, newPassword: false });
      toast.error("Las contraseñas no coinciden", { autoClose: 2000 });
    }
  };

  const DeleteAccount = async () => {
    const data = await SettingsService.DeleteAccount(token, {
      password: modalPassword,
    });

    if (data.code === "200") {
      logout();
    } else {
      toast.error("Oops parece que ha habido un error", { autoClose: 2000 });
    }
  };

  const UpdateSocialMedia = async () => {

    const socialArray = accountCurrentSocialMedia.length > 0 ? accountCurrentSocialMedia : socialMediaInitialBase;

    const updatedSocialMediaArray = socialArray.filter(
      (item) => item.name !== currentSocialMedia.name
    );

    updatedSocialMediaArray.push({
      name: currentSocialMedia.name,
      url: socialMediaLink,
    });

    // console.log(updatedSocialMediaArray);

    try {
      const data = await AccountService.updateSocialMedia(
        token,
        updatedSocialMediaArray
      );
      // console.log(data);
      if(data.code === "UPDATESOCIALMEDIA") {
        setOpenSocialModal(false)
        setSocialMediaLink("")
      }
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    if (country === "colombia") {
      colombia.forEach((loc) => {
        if (loc.nombre === user.state && countryState === "") {
          setMunicipalityList(loc.municipios);
        }
      });
    }

    if (country === "spain") {
      spain.forEach((loc) => {
        if (loc.nombre === user.state && countryState === "") {
          setMunicipalityList(loc.provincias);
        }
      });
    }
  }, [countryState, settingInfo.department, country, user.state]);

  return (
    <>
      <Header />
      <Box
        sx={{
          width: { xs: 60, md: 80 },
          height: { xs: 60, md: 80 },
          bgcolor: "white",
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: 300,
          display: activeAccount ? "none" : "flex",
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: activeAccount ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 10,
        }}
      >
        <Typography textAlign={"center"}>
          Añade tu foto de perfil y una descripcion para comenzar!
        </Typography>
      </Box>
      <Box
        component={"main"}
        sx={{
          mt: { xs: 3, md: 12.5 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: { xs: "100%", md: "100%" },
          mb: { xs: 3, md: 30 },
          position: "relative",
          zIndex: { xs: 99, md: 110 },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "35%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRight: { xs: "", md: "solid 0.1vw #ADADAD" },
          }}
        >
          <Box
            component={"form"}
            ref={uploadForm}
            name="changeImageProfile"
            onSubmit={(e) => handleSubmitImage(e)}
          >
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
              name="file"
              id="selectImageProfile"
              style={{ display: "none" }}
              onChange={(e) => handleInputImagePic(e)}
            />
            <label htmlFor="selectImageProfile">
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!load ? (
                  buttonsLoad.image ? (
                    <Box
                      sx={{
                        width: 190,
                        height: 150,
                        mt: { xs: 0, md: 3 },
                        mb: { xs: 3, md: 3 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "solid 0.1vw #ADADAD",
                      }}
                    >
                      <CircularProgress sx={{ color: "#ADADAD" }} />
                    </Box>
                  ) : (
                    <Avatar
                      sx={{
                        width: 150,
                        height: 150,
                        mt: { xs: 0, md: 3 },
                        mb: { xs: 3, md: 3 },
                        cursor: "pointer",
                      }}
                      src={selectedImage}
                    />
                  )
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    sx={{
                      width: 190,
                      height: 150,
                      mt: { xs: 0, md: 3 },
                      mb: { xs: 3, md: 3 },
                    }}
                  />
                )}
              </Box>
            </label>
            <button
              style={{ display: "none" }}
              type="submit"
              id="btnSubmitUpdateProfile"
            >
              submit
            </button>
          </Box>
          {!load ? (
            <Box
              sx={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                value={settingInfo.fullname}
                type="text"
                sx={{ m: 1, width: 250, bgcolor: "white" }}
                label="Nombre de usuario"
                onChange={(e) => handleChangeData(e)}
                name="fullname"
              />
              <LoadingButton
                variant="contained"
                loading={buttonsLoad.fullname}
                onClick={() => handleSubmit()}
              >
                Actualizar
              </LoadingButton>
            </Box>
          ) : (
            <Box
              sx={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ m: 1, width: 250 }}
                height={60}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                width={125}
                height={40}
              />
            </Box>
          )}
          {!load ? (
            <Box
              sx={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextareaAutosize
                value={settingInfo.description}
                onChange={(e) => handleChangeData(e)}
                type="text"
                sx={{ m: 1, width: 250, bgcolor: "white" }}
                // multiline
                placeholder="Descripción"
                maxRows={3}
                name="description"
              />
              <LoadingButton
                variant="contained"
                loading={buttonsLoad.description}
                onClick={() => handleSubmitDesc()}
              >
                Actualizar
              </LoadingButton>
            </Box>
          ) : (
            <Box
              sx={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ m: 1, width: 250 }}
                height={110}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                width={125}
                height={40}
              />
            </Box>
          )}
          <Typography sx={{ m: { xs: 2, md: 1 }, mt: 1, }}>
            Redes sociales
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              borderTop: "0.1vw solid #ADADAD",
              borderBottom: "0.1vw solid #ADADAD"
              
            }}
          >
            {socialMediaArray.map((socialMedia) => (
              <Box
                key={socialMedia.name}
                onClick={() => {
                  setCurrentSocialMedia(socialMedia);
                  handleOpenSocialModal();
                }}
                sx={{
                  width: 37,
                  height: 37,
                  backgroundImage: "url(" + socialMedia.image + ")",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: 1,
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 8,
              width: "80%",
            }}
          >
            <Button
              sx={{ borderBottom: "solid 0.1vw #ADADAD", borderRadius: 0 }}
              onClick={() => {
                logout();
              }}
            >
              Cerrar sesion
            </Button>
            <a href="https://wa.me/qr/266VEBGQUDPDA1" style={{}}>
            <Button
              sx={{ borderBottom: "solid 0.1vw #ADADAD", borderRadius: 0, width: "100%"}}
            >
              Contacto
            </Button>
            </a>
            <Button
              onClick={handleOpen}
              sx={{ borderBottom: "solid 0.1vw #ADADAD", borderRadius: 0 }}
            >
              Borrar cuenta
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "65%" },
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "80%" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
            onSubmit={(e) => {
              UpdateAccountInfo(e);
            }}
          >
            <Typography sx={{ m: { xs: 2, md: 1 } }}>
              Actualizar informacion
            </Typography>
            {!load ? (
              <TextField
                onChange={(e) => {
                  handleChangeData(e);
                }}
                value={settingInfo.email}
                type="email"
                label="Correo"
                name="email"
                sx={{
                  m: { xs: 2, md: 1 },
                  width: { xs: "80%", md: "100%" },
                  bgcolor: "white",
                }}
              />
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{
                  m: { xs: 2, md: 1 },
                  width: { xs: "80%", md: "100%" },
                  height: 60,
                }}
              />
            )}
            {!load ? (
              <TextField
                onChange={(e) => {
                  handleChangeData(e);
                }}
                value={settingInfo.phone}
                type="number"
                label="Numero de telefono"
                name="phone"
                sx={{
                  m: { xs: 2, md: 1 },
                  width: { xs: "80%", md: "100%", bgcolor: "white" },
                }}
              />
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{
                  m: { xs: 2, md: 1 },
                  width: { xs: "80%", md: "100%" },
                  height: 60,
                }}
              />
            )}
            {!load ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  m: { xs: 2, md: 1 },
                }}
              >
                <TextField
                  id="gender-of-account"
                  select
                  label="Género"
                  value={settingInfo.gender}
                  onChange={(e) => {
                    handleChangeData(e);
                  }}
                  error={!!errors.gender}
                  name="gender"
                  helperText={errors.gender ? errors.gender : ""}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                >
                  <MenuItem value={"male"}>Masculino</MenuItem>
                  <MenuItem value={"female"}>Femenino</MenuItem>
                  <MenuItem value={"other"}>Otro</MenuItem>
                </TextField>

                <TextField
                  id="country-of-account"
                  select
                  label="Pais"
                  value={settingInfo.country}
                  onChange={(e) => {
                    handleChangeData(e);
                    setCountry(e.target.value);
                  }}
                  error={!!errors.country}
                  name="country"
                  helperText={errors.country ? errors.country : ""}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                >
                  <MenuItem value={"colombia"}>Colombia</MenuItem>
                  <MenuItem value={"spain"}>España</MenuItem>
                </TextField>

                <TextField
                  id="department-of-account"
                  select
                  label="Departamento"
                  value={settingInfo.department}
                  onChange={(e) => {
                    handleChangeData(e);
                    setCountryState(e.target.value);

                    if (country === "colombia") {
                      colombia.forEach((loc) => {
                        if (loc.nombre === e.target.value) {
                          setMunicipalityList(loc.municipios);
                        }
                      });
                    }

                    if (country === "spain") {
                      spain.forEach((loc) => {
                        if (loc.nombre === e.target.value) {
                          setMunicipalityList(loc.provincias);
                        }
                      });
                    }
                  }}
                  error={!!errors.department}
                  name="department"
                  helperText={errors.department ? errors.department : ""}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                >
                  {country === "colombia" &&
                    colombia.map((loc, index) => (
                      <MenuItem key={index} value={loc.nombre}>
                        {loc.nombre}
                      </MenuItem>
                    ))}
                  {country === "spain" &&
                    spain.map((loc, index) => (
                      <MenuItem key={index} value={loc.nombre}>
                        {loc.nombre}
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  id="department-of-account"
                  select
                  label="Municipio"
                  value={settingInfo.municipality}
                  onChange={(e) => {
                    handleChangeData(e);
                  }}
                  error={!!errors.municipality}
                  name="municipality"
                  helperText={errors.municipality ? errors.municipality : ""}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                >
                  {municipalityList.map((mun, index) => (
                    <MenuItem key={index} value={mun}>
                      {mun}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ) : (
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: { xs: 2, md: 1 }, width: 250, height: 60 }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: { xs: 2, md: 1 }, width: 250, height: 60 }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: { xs: 2, md: 1 }, width: 250, height: 60 }}
                />
              </Box>
            )}
            {!load ? (
              <LoadingButton
                loading={buttonsLoad.userInfoForm}
                variant="contained"
                sx={{ mt: 3 }}
                type="submit"
              >
                Actualizar
              </LoadingButton>
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                width={125}
                height={40}
                sx={{ mt: 3 }}
              />
            )}
          </Box>
          <Box
            component={"form"}
            onSubmit={(e) => {
              UpdateAccountPassword(e);
            }}
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "80%" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography sx={{ mb: 4 }}>Actualizar contraseña</Typography>
            {!load ? (
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  onChange={(e) => {
                    handleChangePasswords(e);
                  }}
                  name="oldPassword"
                  type="password"
                  label="Contraseña actual"
                  value={oldPassword}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                />
                <TextField
                  onChange={(e) => {
                    handleChangePasswords(e);
                  }}
                  name="newPassword"
                  type="password"
                  label="Contraseña nueva"
                  value={newPassword}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                />
                <TextField
                  onChange={(e) => {
                    handleChangePasswords(e);
                  }}
                  name="confirmNewPassword"
                  type="password"
                  label="Confirmar contraseña nueva"
                  value={confirmNewPassword}
                  sx={{
                    width: { xs: "80%", md: "33%" },
                    mb: { xs: 4, md: 0 },
                    bgcolor: "white",
                  }}
                />
              </Box>
            ) : (
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: 1, width: 225, height: 55 }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: 1, width: 225, height: 55 }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ m: 1, width: 225, height: 55 }}
                />
              </Box>
            )}
            {!load ? (
              <LoadingButton
                loading={buttonsLoad.newPassword}
                variant="contained"
                sx={{ mt: 3, mb: { xs: 10, md: 0 } }}
                type="submit"
              >
                Actualizar
              </LoadingButton>
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                width={125}
                height={40}
                sx={{ mt: 3, mb: { xs: 10, md: 0 } }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSocialModal}
        onClose={handleCloseSocialModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSocialModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: 800 },
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundImage: "url(" + currentSocialMedia.image + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                margin: 4,
                cursor: "pointer",
              }}
            />
            <Typography sx={{ mb: 4, textAlign: "center" }}>
              Ingresa el link de tu perfil de {currentSocialMedia.name}
            </Typography>
            <TextField
              type="text"
              label="link de tu perfil"
              name="socialmediaLink"
              sx={{ mb: 4 }}
              value={socialMediaLink}
              onChange={(e) => {
                setSocialMediaLink(e.target.value);
              }}
            />
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Button
                sx={{ mr: 2 }}
                onClick={() => {
                  handleCloseSocialModal();
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={buttonsLoad.delete}
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => {
                  UpdateSocialMedia();
                }}
              >
                Actualizar
              </LoadingButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: 800 },
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            <ReportIcon color="primary" sx={{ fontSize: 50, m: 4 }} />
            <Typography sx={{ mb: 4, textAlign: "center" }}>
              ¿Estas seguro de que quieres borrar tu cuenta? no podras
              recuperarla.
            </Typography>
            <TextField
              type="password"
              label="Contraseña"
              name="password"
              sx={{ mb: 4 }}
              value={modalPassword}
              onChange={(e) => {
                setModalPassword(e.target.value);
              }}
            />
            <Box
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Button
                sx={{ mr: 2 }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={buttonsLoad.delete}
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => {
                  DeleteAccount();
                }}
              >
                Borrar
              </LoadingButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 100,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />

      {/* <ChangeProfileImages 
        open={openChangeProfileImage}
        setOpen={setOpenChangeProfileImage}
      /> */}
    </>
  );
};

export default Settings;

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[700]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${theme.palette.primary};
  }

  &:focus {
    border-color: ${theme.palette.primary};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
